import { useEffect, useState } from "react";
import axios from "axios";
import { useGetUserID } from "../hook/useGetUserID";
import { useCookies } from "react-cookie";

export const Home = () => {
  const [recipes, setRecipes] = useState([]); // fetching recipe data from api
  const [savedRecipes, setSavedRecipes] = useState([]); // keep track if this recipe has been saved
  const userID = useGetUserID();
  const [cookies, _] = useCookies(["access_token"]);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await axios.get("http://localhost:3001/recipes");
        const recipesWithVisibility = response.data.map((recipe) => ({
          ...recipe,
          showInstructions: true,
          showIngredients: true, // Set initial visibility to true for ingredients
        }));
        setRecipes(recipesWithVisibility);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchSavedRecipes = async () => {
      try {
        const response = await axios.get(
          `http://localhost:3001/recipes/savedRecipes/ids/${userID}`
        );
        setSavedRecipes(response.data.savedRecipes);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRecipes();
    fetchSavedRecipes();
  }, [userID]);

  const saveRecipe = async (recipeID) => {
    try {
      const response = await axios.put(
        "http://localhost:3001/recipes",
        {
          recipeID,
          userID,
        },
        { headers: { authorization: cookies } }
      );
      setSavedRecipes(response.data.savedRecipes);
    } catch (error) {
      console.error(error);
    }
  };

  const isRecipeSaved = (id) => savedRecipes && savedRecipes.includes(id);

  const toggleShowInstructions = (recipeID) => {
    setRecipes((prevRecipes) =>
      prevRecipes.map((recipe) =>
        recipe._id === recipeID
          ? { ...recipe, showInstructions: !recipe.showInstructions }
          : recipe
      )
    );
  };

  const toggleShowIngredients = (recipeID) => {
    setRecipes((prevRecipes) =>
      prevRecipes.map((recipe) =>
        recipe._id === recipeID
          ? { ...recipe, showIngredients: !recipe.showIngredients }
          : recipe
      )
    );
  };

  return (
    <div className="">
      <h1>Recipes</h1>

      <ul>
        {recipes.map((recipe) => (
          <li key={recipe._id}>
            <div>
              <h2>{recipe.name}</h2>
              <button
                onClick={() => saveRecipe(recipe._id)}
                disabled={isRecipeSaved(recipe._id)}
              >
                {isRecipeSaved(recipe._id) ? "Saved" : "Save"}
              </button>
            </div>

            <img src={recipe.imageUrl} alt={recipe.name} />

            <p>Cooking time: {recipe.cookingTime} minutes</p>

            <button onClick={() => toggleShowInstructions(recipe._id)}>
              {recipe.showInstructions
                ? "Hide Instructions"
                : "Show Instructions"}
            </button>
            {recipe.showInstructions && <p>{recipe.instructions}</p>}

            <button onClick={() => toggleShowIngredients(recipe._id)}>
              {recipe.showIngredients ? "Hide Ingredients" : "Show Ingredients"}
            </button>
            {recipe.showIngredients && (
              <ul>
                {recipe.ingredients.map((ingredient, index) => (
                  <li key={index}>
                    {index + 1}: {ingredient}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
