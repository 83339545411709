import { useEffect, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useGetUserID } from "../hook/useGetUserID";

export const SaveRecipes = () => {
  const [savedrecipes, setSavedRecipes] = useState([]); //fetching recipe data from api
  const [showInStructions, setShowinstructions] = useState(true);

  const [showIngredients, setShowingredents] = useState(true);
  const userID = useGetUserID();

  useEffect(() => {
    const fetchSavedRecipe = async () => {
      try {
        const response = await axios.get(
          `http://localhost:3001/recipes/savedRecipes/${userID}`
        );
        setSavedRecipes(response.data.savedRecipes);
        // console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSavedRecipe();
  }, []);

  const toggleShowinstructions = () => {
    setShowinstructions(!showInStructions);
  };

  const toggleShowingredients = () => {
    setShowingredents(!showIngredients);
  };
  return (
    <div>
      <h1>SavedRecipes</h1>

      <ul>
        {savedrecipes.map((recipe) => (
          <li key={recipe._id}>
            <div>
              <h2>{recipe.name}</h2>
            </div>
            <div className="instructions">
              <p>{recipe.instructions}</p>
            </div>
            <img src={recipe.imageUrl} alt={recipe.name} />
            <p>Cooking time: {recipe.cookingTime} mintues</p>
            {/* <button onClick={toggleShowinstructions}>
              {showInStructions ? "Hide Instructions" : "Show Instructions"}
            </button> */}
            {showInStructions && <p>{recipe.instructions}</p>}

            {/* <button onClick={toggleShowingredients}>
              {showIngredients ? "Hide Ingredients" : "Show Ingredients"}
            </button> */}
            {showIngredients && (
              <ul>
                {recipe.ingredients.map((ingredient, index) => (
                  <li key={index}>
                    {index + 1}: {ingredient}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
